import React from "react"
const Container = ({ children }) => {
return (
      <div
        style={{
          margin: `0 auto`,
          maxWidth: '960px',
          width: '100%',
          padding: `50px`,
          
        }}
      >
        {children}
       
      </div>
  
  )
}
export default Container