import React, { useContext } from 'react';
import { Link } from "gatsby";
import SEO from "../components/seo";
import {FirebaseContext} from '../components/Firebase';
import styled from 'styled-components';
import Container from  '../components/container';
//import ProductComments from '../components/common/ProductComments';

import Img from "gatsby-image"

const PostTemplate = (props) =>{

   const { firebase } = useContext(FirebaseContext)

    return(
        <section>
             
            <SEO title={props.pageContext.name} description='Our products have a very narrow focus and are ideal for historical renovation, high-end residential and boutique commercial buildings' />
        
           
            <StyledImage fluid={props.pageContext.localImage.childImageSharp.fluid} alt={props.pageContext.brand} />  
            <Container>
                <h2>{props.pageContext.name}</h2>
                <div dangerouslySetInnerHTML={{ __html: props.pageContext.content }}></div>
                <Link to="/">Go back </Link>
                {/* {!!firebase &&
                    <ProductComments productId={props.pageContext.id} firebase={firebase} />
                } */}
                
            </Container>
       
        </section>
    )
}
const StyledImage = styled(Img)`
    height : 65vw;
    max-height: 88vh; 
    position: relative;
    margin-bottom: 3px; 
`
export default PostTemplate;